import React from 'react';

import Routes from './routes';

import GlobalStyle from './styles/global';

const App = () => (
  <>
    <Routes />
    <GlobalStyle />
  </>
);
export default App;
